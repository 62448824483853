/**
 * All api End points have been brought here
 */
import {
  BLG_API_GET_BLOG_POSTS,
  BLG_API_SEARCH_BLOG_POSTS,
  BLG_API_SHOW_BLOG_POST,
  SEND_MESSAGE,
  SUBSCRIBE_TO_NEWSLETTER,
} from "./apiThreads";

// export const API_BASE_URL = "http://localhost:3333";
export const API_BASE_URL = "https://api.abundantblessinguganda.com/";
export const base_url = (url, user = "/admin") => API_BASE_URL + user + url;

const makeUrl = (endPoint, params = {}) => {
  for (var param in params) {
    endPoint = endPoint.replace(param, params[param]);
  }
  // support without [:]
  var oldEndPoint;
  do {
    oldEndPoint = endPoint;
    endPoint = endPoint.replace(":", "");
  } while (oldEndPoint !== endPoint);

  return base_url(endPoint, "");
};

export const makeEndPoint = makeUrl;

export const apiEndPoints = {
  [SEND_MESSAGE]: {
    endPoint: makeUrl("/app/contact-us/send-message"),
    method: "POST",
  },
  [SUBSCRIBE_TO_NEWSLETTER]: {
    endPoint: makeUrl("/app/newsletter/subscribe"),
    method: "POST",
  },

  // BLOG API
  [BLG_API_GET_BLOG_POSTS]: {
    endPoint: (params) => makeUrl("/app/blog/posts/all", params),
    method: "get",
  },
  [BLG_API_SHOW_BLOG_POST]: {
    endPoint: (params) => makeUrl("/app/blog/posts/show/:post_id", params),
    method: "get",
  },
  [BLG_API_SEARCH_BLOG_POSTS]: {
    endPoint: (params) => makeUrl("/app/blog/posts/search", params),
    method: "GET",
  },
};
